export const services = [
  {
    icon: 'fas fa-layer-group',
    title: 'Built for developers',
    subtitle:
      'TheFront is built to make your life easier. Variables, build tooling, documentation, and reusable components.',
  },
  {
    icon: 'fab fa-sketch',
    title: 'Designed to be modern',
    subtitle:
      'Designed with the latest design trends in mind. TheFront feels modern, minimal, and beautiful.',
  },
  {
    icon: 'fas fa-code',
    title: 'Documentation for everything',
    subtitle:
      "We've written extensive documentation for components and tools, so you never have to reverse engineer anything.",
  },
];

export const partners = [
  {
    name: 'Airbnb',
    logo: 'https://assets.maccarianagency.com/the-front/logos/airbnb.svg',
  },
  {
    name: 'Coinbase',
    logo: 'https://assets.maccarianagency.com/the-front/logos/coinbase.svg',
  },
  {
    name: 'Dribbble',
    logo: 'https://assets.maccarianagency.com/the-front/logos/dribbble.svg',
  },
  {
    name: 'Instagram',
    logo: 'https://assets.maccarianagency.com/the-front/logos/instagram.svg',
  },
  {
    name: 'Netflix',
    logo: 'https://assets.maccarianagency.com/the-front/logos/netflix.svg',
  },
  {
    name: 'Pinterest',
    logo: 'https://assets.maccarianagency.com/the-front/logos/pinterest.svg',
  },
];

export const props = [
  'Lifetime updates',
  'Tech support',
  'Tons of assets',
  'Integration ready',
];

export const users = [
  {
    logo: 'https://assets.maccarianagency.com/the-front/logos/slack.svg',
    name: 'Slack',
  },
  {
    logo: 'https://assets.maccarianagency.com/the-front/logos/mailchimp.svg',
    name: 'Mailchimp',
  },
  {
    logo: 'https://assets.maccarianagency.com/the-front/logos/dropbox.svg',
    name: 'Dropbox',
  },
  {
    logo: 'https://assets.maccarianagency.com/the-front/logos/google-drive.svg',
    name: 'Google Drive',
  },
  {
    logo: 'https://assets.maccarianagency.com/the-front/logos/google-ad-manager.svg',
    name: 'Google Ad Manager',
  },
  {
    logo: 'https://assets.maccarianagency.com/the-front/logos/atlassian.svg',
    name: 'Atlassian',
  },
];

export const pricings = [
  {
    title: 'Standard License',
    subtitle: 'A pay-once license, just for you',
    price: '$49',
    priceSuffix: ' / MO',
    features: [
      'Rich, responsive landing pages',
      '100+ styled components',
      'Flexible, simple license',
      'Speedy build tooling',
      '6 months free support included',
    ],
    disclaimer: 'Fully featured 30-day free trial',
    isHighlighted: false,
  },
  {
    title: 'Extended License',
    subtitle: 'A pay-once license, just for you',
    price: '$79',
    priceSuffix: ' / MO',
    features: [
      'Rich, responsive landing pages',
      '100+ styled components',
      'Flexible, simple license',
      'Speedy build tooling',
      '6 months free support included',
    ],
    disclaimer: 'Fully featured 30-day free trial',
    isHighlighted: true,
  },
  {
    title: 'Standard License',
    subtitle: 'A pay-once license, just for you',
    price: '$49',
    priceSuffix: ' / MO',
    features: [
      'Rich, responsive landing pages',
      '100+ styled components',
      'Flexible, simple license',
      'Speedy build tooling',
      '6 months free support included',
    ],
    disclaimer: 'Fully featured 30-day free trial',
    isHighlighted: false,
  },
];

export const faq = [
  {
    id: 'faq-1',
    title: 'Flexible access to facilities.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
  {
    id: 'faq-2',
    title: 'Snacks, drinks, coffee, and more.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
  {
    id: 'faq-3',
    title: 'On site tech support.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
  {
    id: 'faq-4',
    title: 'Flexible access to facilities.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
  {
    id: 'faq-5',
    title: 'Snacks, drinks, coffee, and more.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
  {
    id: 'faq-6',
    title: 'On site tech support.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
];

export const reviews = [
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
    },
    authorName: 'Veronica Adams',
    authorOccupation: 'Growth Marketer, Crealytics',
    feedback:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
    },
    authorName: 'Akachi Luccini',
    authorOccupation: 'Lead Generation, Alternative Capital',
    feedback:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith@2x.jpg 2x',
    },
    authorName: 'Jack Smith',
    authorOccupation: 'Head of Operations, Parkfield Commerce',
    feedback:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
    },
    authorName: 'Veronica Adams',
    authorOccupation: 'Growth Marketer, Crealytics',
    feedback:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
    },
    authorName: 'Akachi Luccini',
    authorOccupation: 'Lead Generation, Alternative Capital',
    feedback:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith@2x.jpg 2x',
    },
    authorName: 'Jack Smith',
    authorOccupation: 'Head of Operations, Parkfield Commerce',
    feedback:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },
];
